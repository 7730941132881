<template>
  <div class="p-4 require-mobile m-0 absolute top-1/2 ">
    <div>
      <h2 class="text-center pb-4 ">
        Chào mừng bạn đã ghé thăm Interloan!
      </h2>
      <div class="img-wrapper overflow-hidden text-center">
        <img src="@/assets/images/intro.svg" alt="intro-1" class="inline" />
      </div>
      <div class="text-center py-4">
        <div>
          Interloan là ứng dụng công nghệ tài chính giúp bạn:
        </div>
        <div
          class="text-xl font-bold"
          style="
              line-height: 30px;
              margin: 0 auto 0.5rem auto;"
        >
          Ứng lương nhanh chóng, Đầu tư hiệu quả
        </div>
        <div class="flex items-center flex-col">
          <div class="text-left">
            <icon-check-circle
              class="inline-flex self-start mr-0.5 text-success"
            />
            <span class="relative -bottom-0.5">
              Ứng lương trong <b>5 phút</b><br />
            </span>
            <icon-check-circle
              class="inline-flex self-start mr-0.5 text-success"
            />
            <span class="relative -bottom-0.5">
              Đầu tư sinh lời lên tới <b>19% năm</b>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="border-2 border-dashed border-tertiary text-center flex justify-center items-center font-bold text-2xl p-2.5 rounded-xl"
    >
      Vui lòng truy cập từ điện thoại của bạn
    </div>
  </div>
</template>

<script lang="ts">
import IconCheckCircle from '@/components/Icon/IconCheckCircle.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RequireMobile',
  components: { IconCheckCircle },
});
</script>

<style scoped lang="scss">
.require-mobile {
  transform: translateY(-50%);

  .img-wrapper {
    max-height: 240px;
  }
}
</style>
